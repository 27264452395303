.contact-root {
  padding-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;

  & > .title {
    font-family: Futura, Trebuchet MS, Arial, sans-serif;
    font-size: 30px;
    font-weight: 500;
    margin-bottom: 20px;
  }

  & > .content {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    text-align: left;


    & > .l {
      display: flex;
      width: 300px;
      margin: 0 30px;
      justify-content: center;

      & img {
        width: 300px;
        object-fit: contain;
        object-position: center;
      }
    }

    & > .r {
      width: 300px;
      margin: 0 30px;

      & .MuiSvgIcon-root {
        margin-right: 10px;
      }

      & .socials {
        & img {
          width: 25px;
          height: 25px;
        }

        & .MuiSvgIcon-root {
          margin-right: 0;
        }

        & .resume {
          padding: 3px 10px;
          margin-left: 20px;
          border-radius: 3px;
          background-color: #eeeeee;
          font-weight: 700;
          color: #008fad;
          transition: color 200ms, background-color 200ms;
          &:hover{
            background-color: #282c34;
            color: white;

          }
        }
      }

      & .story {
        margin-top: 30px;
      }

      & > div {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
      }

      & .title {
        font-size: 20px;
        font-weight: 700;
        margin-bottom: 0;
      }
    }
  }

  & .hire {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 400px;
    height: 100px;
    margin: 30px 0;
    border-radius: 3px;
    font-size: 40px;
    font-family: Futura, Trebuchet MS, Arial, sans-serif;
    color: white;
    text-decoration: none;
    background-image: url("./assets/banner2.png");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;
    transition: filter 300ms;
    filter: drop-shadow(0px 3px 14px rgba(0, 0, 0, 0));

    & .MuiSvgIcon-root {
      font-size: 45px;
      margin-right: 5px;
    }

    &:hover {
      filter: drop-shadow(0px 13px 14px rgba(0, 0, 0, 0.4));
    }
  }
}