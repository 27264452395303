.works-root {
  width: 100%;
  align-items: center;

  & .intro {
    font-family: Futura, Trebuchet MS, Arial, sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
  }

  & .images {
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    & .image-container {

      position: relative;
      cursor: pointer;
      width: 25%;
      height: 25%;

      & img {
        display: block;
        width: 100%;
        height: 100%;
        aspect-ratio: 1 / 1;
        object-fit: cover;
      }

      & .backdrop {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.6);
        opacity: 0;
        transition: opacity 200ms;
        will-change: opacity;

        & > .name {
          font-family: Futura, Trebuchet MS, Arial, sans-serif;
          color: white;
          font-size: 20px;

        }

        &:hover {
          opacity: 1;
        }
      }
    }
  }
}

.modal-content {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  //border: solid 2px white;
  outline: none;


  & > img {
    display: block;
    max-height: 80%;
    max-width: 90%;
    object-fit: contain;

    &::selection {
      background: transparent;
    }
  }

  & > .text {
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
    &.name {
      margin-top: 10px;
      color: white;
      font-family: Futura, Trebuchet MS, Arial, sans-serif;
      font-size: 25px;
    }

    &.description {
      color: #bbbbbb;

    }
  }

  & > .arrows {
    pointer-events: none;
    position: absolute;
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 100%;

    & .arrow {
      pointer-events: auto;
      display: flex;
      align-items: center;
      height: 100%;
      width: 20%;
      box-sizing: border-box;
      padding: 0 5%;
      color: #919191;
      transition: color 200ms;
      cursor: pointer;

      &:hover {
        color: white;

      }

      &.l {
      }

      &.r {
        justify-content: flex-end;

      }

      & .MuiSvgIcon-root {
        font-size: 40px;

      }
    }
  }
}