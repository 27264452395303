.App {
  position: relative;
  min-width: 400px;
  text-align: center;
  background-color: white;
  min-height: 100vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vm);

  & .header {
    position: fixed;
    z-index: 998;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 50px;
    max-height: 50px;
    box-sizing: border-box;
    padding: 0 10%;
    background-color: #282c34;
    font-family: Futura, Trebuchet MS, Arial, sans-serif;
    color: white;

    & > .l {
      display: flex;
      align-items: center;
      position: relative;
      font-size: 20px;
      font-weight: 700;
      cursor: pointer;

      & img {
        position: relative;

      }

      & .logo {
        width: 80px;
        top: 10px;
        margin-right: 10px;
        @media (max-width: 450px) {
          display: none;
        }
      }

      & .logo2 {
        height: 40px;
        @media (max-width: 700px) {
         display: none;
        }
      }
    }

    & > .r {
      display: flex;

      & > a {
        min-width: 80px;
        padding: 10px;
        margin: 0 2px;
        border-radius: 3px;
        cursor: pointer;
        text-decoration: none;
        color: white;
        background-color: rgba(255, 255, 255, 0);
        transition: background-color 200ms, color 200ms;

        &.active {
          background-color: rgba(255, 255, 255, 1);
          color: black;
        }

        &:hover {
          background-color: rgba(255, 255, 255, 0.4);

        }

      }
    }
  }

  & .main_container {
    overflow-y: auto;
    padding-top: 50px;

  }

  & .footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 100px;
    padding-bottom: 40px;

    & .grey {
      color: lightgray;
    }
  }
}

